import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { IndexQuery } from '../graphql-types';
import IndexUnauth from '../screens/unauth/IndexUnauth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getFirebaseAuth } from '../services/firebase';
import IndexAuth from '../screens/auth/IndexAuth';

const IndexPage = () => {
  const indexPageData = useStaticQuery<IndexQuery>(graphql`
    query Index {
      allContentfulLanding {
        edges {
          node {
            desktopCarouselPictures {
              fixed(width: 1600, toFormat: JPG) {
                src
                width
              }
              contentful_id
            }
            mobileCarouselPictures {
              fixed(toFormat: JPG, width: 1080) {
                src
                width
              }
              contentful_id
            }
            shareImage {
              fixed(
                resizingBehavior: FILL
                cropFocus: CENTER
                toFormat: JPG
                height: 630
                width: 1200
                quality: 90
              ) {
                src
              }
            }
            featuredTherapists {
              fullName
              shortHeadline
              title
              profilePhoto {
                resize(
                  height: 500
                  width: 500
                  resizingBehavior: FILL
                  cropFocus: FACE
                  jpegProgressive: true
                ) {
                  src
                }
              }
              slug
            }
            imageLinks
          }
        }
      }

      featuredArticles: allContentfulArticle(
        filter: { featured: { eq: true } }
        limit: 2
      ) {
        edges {
          node {
            id
            slug
            title
            subtitle
            publishedDate
            coverImage {
              fluid(maxWidth: 1000) {
                ...GatsbyContentfulFluid_noBase64
              }
            }
          }
        }
      }
      leftTherapist: contentfulTherapist(slug: { eq: "eliana-riccardi" }) {
        profilePhoto {
          fixed(width: 600, height: 400) {
            src
          }
        }
        title
        fullName
        therapyTypes
        sessionPricing
      }
      centreTherapist: contentfulTherapist(slug: { eq: "scott-houston" }) {
        profilePhoto {
          fixed(width: 600, height: 400) {
            src
          }
        }
        title
        fullName
        therapyTypes
        sessionPricing
      }
      rightTherapist: contentfulTherapist(
        slug: { eq: "george-bowlay-williams" }
      ) {
        profilePhoto {
          fixed(width: 600, height: 400) {
            src
          }
        }
        title
        fullName
        therapyTypes
        sessionPricing
        slug
      }
    }
  `);

  const [user, loading, error] = useAuthState(getFirebaseAuth());

  return user ? (
    <IndexAuth indexPageData={indexPageData} />
  ) : (
    <IndexUnauth indexPageData={indexPageData} />
  );
};

export default IndexPage;
