import React, { HTMLAttributes, useState } from 'react';
import { Card, CardBody, CardImg, CardTitle } from 'reactstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCoffee,
  faInfoCircle,
  faMapMarker,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';
import { MainButton } from '../Button';
import { Badge, CardFooter } from 'reactstrap';
import { navigate } from '@reach/router';
import {
  Ethnicities,
  Genders,
  Sexualities,
  Specialisms,
  TherapyTypes,
} from '../../utils/match';
import { theme } from '../../theme';
import Button from '../../reusecore/src/elements/Button/button';
import Box from '../../reusecore/src/elements/Box';
import Text from '../../reusecore/src/elements/Text/text';
import CustomText from '../../reusecore/src/elements/Text/text';

export enum Insurer {
  BUPA,
  VITALITY,
}

export type TherapistCardCategories = Specialisms | TherapyTypes;

export interface MatchTherapistCardProps
  extends HTMLAttributes<HTMLDivElement> {
  slug: string;
  profileImageSrc: string;
  score?: number;
  fullName: string;
  title: string;
  distanceMiles?: number;
  headline?: string;
  pricePerSession: number;
  insurers?: Insurer[];
  therapyTypes?: TherapyTypes[];
  specialisms?: Specialisms[];
  demographics?: (Genders | Sexualities | Ethnicities)[];
  greyed?: boolean;
  demo?: boolean;
  directory?: boolean;
}

const TherapistCardCollar = styled(CardTitle)`
  text-align: left;
  padding: 1rem 1.5rem;
  background-color: #0f6674;
`;

const ScoreCollarTitle = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
`;

const ScoreCollarText = styled.p`
  color: white;
  margin: 0;
`;

const TherapistCardBody = styled(CardBody)`
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
`;

const TherapistCardName = styled.h4`
  text-align: left;
  font-weight: 700;
  margin-bottom: 0;
`;

const TherapistCardSubtitle = styled.h6`
  text-align: left;
  margin-bottom: 1rem;
  color: #868e96;
`;

const TherapistCardButton = styled(MainButton)`
  align-self: center;
  width: 50%;
`;

const TherapistCardFooter = styled(CardFooter)`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-top: 1px solid #e6e6e6;
  padding: 1rem 1.5rem;
`;

const GreyOutSheet = styled.div`
  background-color: rgba(67, 69, 71, 0.4);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

export const getCategoryPillColour = (
  category: TherapistCardCategories
): string => {
  return 'black';
};

export const MatchTherapistCard: React.FunctionComponent<MatchTherapistCardProps> = ({
  slug,
  title,
  children,
  headline,
  fullName,
  distanceMiles,
  pricePerSession,
  profileImageSrc,
  therapyTypes,
  specialisms,
  demographics,
  score,
  greyed,
  style,
  demo,
  directory,
}): JSX.Element => {
  const [expanded, setExpanded] = useState<boolean>(false);

  //
  return (
    <Card
      className="rounded"
      style={{
        maxWidth: 400,
        position: 'relative',
        boxShadow: theme.shadows.card,
        ...style,
      }}
    >
      {greyed && <GreyOutSheet className="rounded" />}
      <CardImg top className="img-fluid rounded-top" src={profileImageSrc} />
      {score && (
        <Box py={3} px={3} bg="secondary">
          {/* Full name with final initial as first letter.*/}
          <Text
            fontFamily="heading"
            fontSize={2}
            fontWeight={3}
            color="white"
            m={0}
          >
            {!directory && (
              <FontAwesomeIcon
                icon={faInfoCircle}
                style={{ color: 'white', cursor: 'pointer' }}
                className="mr-2"
                size="lg"
                onClick={() => {
                  !directory && !demo && setExpanded(!expanded);
                }}
              />
            )}
            {Math.round(score)}% match
          </Text>
          {expanded && (
            <>
              {!!therapyTypes.length && (
                <Box pb={1}>
                  <Text
                    fontFamily="body"
                    fontSize={11}
                    color="white"
                    m={0}
                    pt={2}
                    pb={1}
                  >
                    You matched on the following therapy types:
                  </Text>
                  {therapyTypes.map<JSX.Element>(x => (
                    <Badge
                      key={x}
                      pill
                      style={{
                        backgroundColor: 'white',
                        marginRight: theme.space[2],
                      }}
                    >
                      <Text
                        fontFamily="body"
                        fontSize={11}
                        color="secondary"
                        m={0}
                      >
                        {x}
                      </Text>
                    </Badge>
                  ))}
                </Box>
              )}
              {!!specialisms.length && (
                <Box pb={1}>
                  <Text
                    fontFamily="body"
                    fontSize={11}
                    color="white"
                    m={0}
                    pt={2}
                    pb={1}
                  >
                    You matched on the following specialisms:
                  </Text>
                  {specialisms.map<JSX.Element>(x => (
                    <Badge
                      key={x}
                      pill
                      style={{
                        backgroundColor: 'white',
                        marginRight: theme.space[2],
                      }}
                    >
                      <Text
                        fontFamily="body"
                        fontSize={11}
                        color="secondary"
                        m={0}
                      >
                        {x}
                      </Text>
                    </Badge>
                  ))}
                </Box>
              )}
              {!!demographics.length && (
                <Box pb={1}>
                  <Text
                    fontFamily="body"
                    fontSize={11}
                    color="white"
                    m={0}
                    pt={2}
                    pb={1}
                  >
                    You matched on the following therapist profile:
                  </Text>
                  {demographics.map<JSX.Element>(x => (
                    <Badge
                      key={x}
                      pill
                      style={{
                        backgroundColor: 'white',
                        marginRight: theme.space[2],
                      }}
                    >
                      <Text
                        fontFamily="body"
                        fontSize={11}
                        color="secondary"
                        m={0}
                      >
                        {x}
                      </Text>
                    </Badge>
                  ))}
                </Box>
              )}
            </>
          )}
        </Box>
      )}
      <TherapistCardBody>
        <TherapistCardName>{fullName}</TherapistCardName>
        <Text fontFamily="body" fontWeight={1} color="muted">
          {title}
        </Text>
        {distanceMiles && (
          <Text fontFamily="body" fontWeight={1} color="muted">
            <FontAwesomeIcon className="mr-1" icon={faMapMarkerAlt} />
            {Math.min(distanceMiles, 20)}
            {distanceMiles > 20 ? '+' : ''} kilometres away
          </Text>
        )}
        {headline && (
          <Text
            fontFamily="body"
            fontWeight={1}
            color="muted"
            className="align-items-start text-break"
          >
            "{headline}"
          </Text>
        )}
      </TherapistCardBody>
      <TherapistCardFooter className="rounded-bottom">
        <Text
          fontFamily="body"
          fontWeight={1}
          color="muted"
          className="d-flex justify-content-between"
        >
          <span>Price per session</span>
          <span>£{pricePerSession}</span>
        </Text>
        <Text fontFamily="body" fontWeight={1} color="muted">
          Initial consultation fee may vary
        </Text>
        <Button
          type="primary"
          my={2}
          px={4}
          alignSelf="center"
          onClick={() => {
            !demo && navigate(`/therapist/${slug}`);
          }}
        >
          View Profile
        </Button>
      </TherapistCardFooter>
    </Card>
  );
};
