import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { useViewport } from '../../utils/UseViewport';

export default function BannerImage(image, height, link, index) {
  const imageComponent = (
    <img
      style={{ maxHeight: height, maxWidth: image.width, width: '100vw' }}
      src={image.src}
      alt="preview"
    />
  );
  if (link) {
    const rawLink = link;
    return rawLink.startsWith('/#', 0) ? (
      <AnchorLink
        to={rawLink}
        className="banner-image"
        key={`banner-image-${index}`}
      >
        {imageComponent}
      </AnchorLink>
    ) : (
      <a
        href={rawLink}
        rel="noopener noreferrer"
        className="banner-image"
        key={`banner-image-${index}`}
      >
        {imageComponent}
      </a>
    );
  } else {
    return imageComponent;
  }
}
