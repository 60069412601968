import React from 'react';
import Layout from '../../components/Layout';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'gatsby';
import { ContentfulFixed, IndexQuery } from '../../graphql-types';
import styled from 'styled-components';
import ControlSection from '../../containers/App/Control';
import ControlSectionTwo from '../../containers/App/ControlTwo';
import TestimonialSection from '../../containers/App/Testimonial';
import { Fade } from 'react-slideshow-image';
import moment from 'moment';
import PostCardModern from '../../components/PostCardModern/postCardModern';
import { PostGrid } from '../../components/Posts/style';
import Text from '../../reusecore/src/elements/Text/text';
import Heading from '../../reusecore/src/elements/Heading';
import colors from '../../theme/colors';
import SEO from '../../components/SEO';
import BannerImage from '../../components/Banner/BannerImage';
import { useViewport } from '../../utils/UseViewport';

const fadeProperties = {
  duration: 5000,
  transitionDuration: 500,
  arrows: false,
};

export interface IndexUnauthProps {
  indexPageData: IndexQuery;
}

const IndexUnauth: React.FunctionComponent<IndexUnauthProps> = ({
  indexPageData,
}) => {
  const { width } = useViewport();
  const maxHeight = width > 1080 ? '500px' : '1080px';
  const maxWidth = width > 1080 ? '1600px' : '1080px';
  const therapistData = [
    ...indexPageData.allContentfulLanding.edges[0].node.featuredTherapists.map(
      x => ({
        fullName: x.fullName,
        headline: x.shortHeadline,
        title: x.title,
        profilePhoto: x.profilePhoto.resize.src,
        slug: x.slug,
      })
    ),
  ];
  const imageLinks =
    indexPageData.allContentfulLanding.edges[0].node.imageLinks;

  const bannerImages =
    width > 1080
      ? indexPageData.allContentfulLanding.edges[0].node.desktopCarouselPictures.map<
          ContentfulFixed
        >(x => x.fixed)
      : indexPageData.allContentfulLanding.edges[0].node.mobileCarouselPictures.map<
          ContentfulFixed
        >(x => x.fixed);

  const BannerContainer = styled.div`
    display: grid;
    place-items: center;
    max-height: ${maxHeight};
    width: 100%;
  `;

  const articles = indexPageData.featuredArticles.edges.map<JSX.Element>(x => (
    <PostGrid key={x.node.slug}>
      <PostCardModern
        key={x.node.slug}
        title={x.node.title}
        image={x.node.coverImage.fluid}
        imageType={'fluid'}
        url={`/article/${x.node.slug}`}
        description={x.node.subtitle}
        date={moment(x.node.publishedDate).format('D MMM YYYY')}
        placeholderBG={'white'}
      />
    </PostGrid>
  ));

  return (
    <Layout>
      <SEO
        metaImage={{
          src: `https:${indexPageData.allContentfulLanding.edges[0].node.shareImage.fixed.src}`,
          height: 630,
          width: 1200,
        }}
      />
      <BannerContainer>
        <Fade
          {...fadeProperties}
          style={{ maxHeight: maxHeight, maxWidth: maxWidth }}
        >
          {bannerImages.map((image, key) =>
            BannerImage(image, maxHeight, imageLinks[key], key)
          )}
        </Fade>
      </BannerContainer>
      <ControlSection
        heading="Learn about your mental health"
        body={[
          'Take our four mental health wellbeing tests to see' +
            ' how you are doing on common mental health issues like stress, anxiety, depression and minority' +
            ' stress, which refers to unique LGBTQ+ stressors, such as internalised homophobia and expectations of rejection.',
          'Once you have completed all four tests, we will provide you with an overall summary to help you' +
            ' understand whether you might benefit from additional support. You can also learn more about your' +
            ' mental health by reading our articles about LGBTQ+ mental health, therapy and other subjects in our Explore section.',
        ]}
        subheading="LEARN"
        imageAreaRow={{ flexDirection: 'row-reverse' }}
        textAreaRow={{ flexDirection: 'row' }}
        buttonText={['TAKE TESTS']}
        blob
      />
      <ControlSectionTwo
        heading="Match with an LGBTQ+ specialised therapist that is right for you"
        body="Let us find the right therapist for you with our LGBTQ+ specific
        matching service. Complete a three-minute questionnaire and we will match you
        with the top three therapists on our platform that fits with your specific
        requirements. All therapists on our platform are LGBTQ+ specialists and have
        been pre-screened to ensure they have the expertise and experience to help you."
        subheading="MATCH"
        imageAreaRow={{ flexDirection: 'row' }}
        textAreaRow={{ flexDirection: 'row-reverse' }}
        buttonText={['MATCH ME']}
        blob
        flip
        therapistLeftInfo={indexPageData.leftTherapist}
        therapistCentreInfo={indexPageData.centreTherapist}
        therapistRightInfo={indexPageData.rightTherapist}
      />
      <TestimonialSection data={therapistData} />
      <Container style={{ backgroundColor: colors.sand }} fluid id="explore">
        <Container
          style={{
            paddingTop: '40px',
            paddingBottom: '40px',
            backgroundColor: colors.sand,
          }}
        >
          <Row className="mb-3">
            <Col>
              <Link to="/explore">
                <Text
                  {...{
                    as: 'span',
                    display: 'block',
                    fontSize: '14px',
                    letterSpacing: '0.13em',
                    fontWeight: '700',
                    color: '#3c8271',
                    mb: '10px',
                    textAlign: ['center'],
                  }}
                >
                  EXPLORE
                </Text>
              </Link>
              <Heading
                {...{
                  content: 'Read our featured articles',
                  fontSize: ['24px', '36px'],
                  fontWeight: '600',
                  color: '#0f2137',
                  letterSpacing: '-0.025em',
                  mb: 2,
                  textAlign: ['center'],
                }}
              />
            </Col>
          </Row>
          <Row>{articles}</Row>
        </Container>
      </Container>
    </Layout>
  );
};

export default IndexUnauth;
