import React from 'react';
import Heading from '../../../reusecore/src/elements/Heading';
import Text from '../../../reusecore/src/elements/Text';
import Box from '../../../reusecore/src/elements/Box';
import Button from '../../../reusecore/src/elements/Button/button';
import { navigate } from 'gatsby';
import { handleLoginModal } from '../Navbar';

const sliderDes = props => {
  return (
    <>
      <Box className="d-none d-sm-block">
        <Text
          content="CONNECT"
          {...{
            as: 'span',
            display: 'block',
            fontSize: '14px',
            letterSpacing: '0.11em',
            fontWeight: '700',
            color: '#3c8271',
            textTransform: 'uppercase',
            mb: '10px',
            textAlign: ['center', 'left'],
          }}
        />
        <Heading
          content="Connect with LGBTQ+ therapists, online or offline"
          {...{
            fontSize: ['24px', '36px'],
            fontWeight: '600',
            color: '#0f2137',
            letterSpacing: '-0.010em',
            mb: '40px',
            maxWidth: ['100%', '460px'],
            textAlign: ['center', 'left'],
          }}
        />
        <Text
          content="FEATURED THERAPISTS"
          {...{
            as: 'span',
            display: 'block',
            fontSize: '14px',
            letterSpacing: '0.11em',
            fontWeight: '700',
            color: '#3c8271',
            textTransform: 'uppercase',
            mb: '25px',
            textAlign: ['center', 'left'],
          }}
        />
        <p className="testimonialDes text-left">“{props.data.description}”</p>
        <Box flexBox flexDirection="column" alignItems="flex-start">
          <div className="testimonialDetails text-left">
            <p className="testimonialName text-left">{props.data.name}</p>
            <span className="testimonialDesignation text-left">
              {props.data.designation}
            </span>
          </div>
          <Button
            onClick={() => {
              props.auth
                ? navigate(`/therapist/${props.data.slug}`)
                : handleLoginModal();
            }}
            px={3}
            flex={0}
          >
            View Profile
          </Button>
        </Box>
      </Box>
      <Box className="d-block d-sm-none">
        <p className="testimonialDes text-left" style={{ marginTop: 10 }}>
          “{props.data.description}”
        </p>
        <Box flexBox flexDirection="column" alignItems="flex-start">
          <div className="testimonialDetails text-left">
            <p className="testimonialName text-left">{props.data.name}</p>
            <span className="testimonialDesignation text-left">
              {props.data.designation}
            </span>
          </div>
          <Button
            onClick={() => {
              props.auth
                ? navigate(`/therapist/${props.data.slug}`)
                : handleLoginModal();
            }}
            px={3}
            flex={0}
          >
            View Profile
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default sliderDes;
