import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from '../../../reusecore/src/elements/Box';
import Container from '../../../superprops/src/components/UI/Container';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import SliderDes from '../sliderDescription';
import Image1 from '../../../superprops/src/assets/image/app/6.png';
import Image2 from '../../../superprops/src/assets/image/app/2.jpg';
import Image3 from '../../../superprops/src/assets/image/app/5.jpg';
import Image4 from '../../../superprops/src/assets/image/app/testi.jpg';
import Image5 from '../../../superprops/src/assets/image/app/1.jpeg';
import colors from '../../../theme/colors';
import Text from '../../../reusecore/src/elements/Text';
import Heading from '../../../reusecore/src/elements/Heading';

const TestimonialSection = ({ sectionWrapper, data, auth }) => {
  return (
    <Box
      {...sectionWrapper}
      className="testimonialSlider"
      id="testimonialSection"
      bg={colors.white}
    >
      <Container>
        <img
          src="https://res.cloudinary.com/pala-cloudinary/image/upload/v1587116719/oldblob.svg"
          style={{
            position: 'absolute',
            top: '0',
            right: '45%',
            zIndex: 1,
            fill: colors.blobGreen,
          }}
          className="d-none d-sm-block"
        />
        <Box
          style={{ position: 'relative', zIndex: 8 }}
          className="testimonialDesWrapper text-left d-none d-sm-block"
        >
          <ImageGallery
            items={data.map(x => ({
              original: x.fullName,
              thumbnail: x.profilePhoto,
              thumbnailAlt: x.fullName,
              thumbnailTitle: x.fullName,
              description: (
                <SliderDes
                  key={x.fullName}
                  data={{
                    description: x.headline,
                    name: x.fullName,
                    designation: x.title,
                    slug: x.slug,
                  }}
                  auth={auth}
                />
              ),
            }))}
            originalClass="Testimonial-img"
            showPlayButton={false}
            showFullscreenButton={false}
            showNav={false}
          />
        </Box>
        <Box
          style={{ position: 'relative', zIndex: 8 }}
          className="testimonialDesWrapper text-left d-block d-sm-none"
        >
          <Text
            content="CONNECT"
            {...{
              as: 'span',
              display: 'block',
              fontSize: '14px',
              letterSpacing: '0.11em',
              fontWeight: '700',
              color: '#3c8271',
              textTransform: 'uppercase',
              mb: '10px',
              textAlign: ['center', 'left'],
            }}
          />
          <Heading
            content="Connect with LGBTQ+ specialised therapists, online or offline"
            {...{
              fontSize: ['24px', '36px'],
              fontWeight: '600',
              color: '#0f2137',
              letterSpacing: '-0.010em',
              mb: '40px',
              maxWidth: ['100%', '460px'],
              textAlign: ['center', 'left'],
            }}
          />
          <Text
            content="FEATURED THERAPISTS"
            {...{
              as: 'span',
              display: 'block',
              fontSize: '14px',
              letterSpacing: '0.11em',
              fontWeight: '700',
              color: '#3c8271',
              textTransform: 'uppercase',
              mb: -20,
              textAlign: ['center', 'left'],
            }}
          />
          <ImageGallery
            items={data.map(x => ({
              original: x.fullName,
              thumbnail: x.profilePhoto,
              thumbnailAlt: x.fullName,
              thumbnailTitle: x.fullName,
              description: (
                <SliderDes
                  key={x.fullName}
                  data={{
                    description: x.headline,
                    name: x.fullName,
                    designation: x.title,
                    slug: x.slug,
                  }}
                  auth={auth}
                />
              ),
            }))}
            originalClass="Testimonial-img"
            showPlayButton={false}
            showFullscreenButton={false}
            showNav={false}
            thumbnailPosition="top"
          />
        </Box>
      </Container>
    </Box>
  );
};

TestimonialSection.propTypes = {
  data: PropTypes.object,
  sectionWrapper: PropTypes.object,
  title: PropTypes.object,
  auth: PropTypes.bool,
};

TestimonialSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['40px', '200px'],
    pb: ['40px', '200px'],
  },

  sectionSubTitle: {
    content: 'CLIENT TESTIMONIAL',
    as: 'span',
    display: 'block',
    textAlign: ['center', 'left'],
    fontSize: '14px',
    letterSpacing: '0.11em',
    fontWeight: '700',
    color: '#3c8271',
    textTransform: 'uppercase',
    mb: '10px',
  },
};

export default TestimonialSection;
