import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';
import Box from '../../reusecore/src/elements/Box';

export interface ClusterKeyProps extends HTMLAttributes<HTMLDivElement> {
  mobile?: boolean;
}

const InfoBox = styled.div`
  -webkit-font-smoothing: antialiased;
  background-color: #fff;
  border-radius: 4em;
  box-shadow: rgba(22, 53, 76, 0.05) 0 7px 25px 0;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  height: 45px;
  margin: 0.5rem 1rem;
  padding: 0 24px 0 6px;
  text-shadow: rgba(0, 0, 0, 0.004) 1px 1px 1px;
  display: flex;
  align-items: center;
  min-width: 19ch;
`;

const InfoBoxButton = styled.div<{ colour: string }>`
  -webkit-font-smoothing: antialiased;
  background-color: ${props => props.colour};
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  box-sizing: border-box;
  color: #fff;
  font-size: 14px;
  margin-bottom: 0;
  margin-right: 0.2rem;
  margin-top: 0;
  text-shadow: rgba(0, 0, 0, 0.004) 1px 1px 1px;
  text-transform: uppercase;
`;

const InfoBoxText = styled.div`
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  color: #0f2137;
  font-size: 1rem;
  margin-bottom: 0;
  margin-top: 0;
  text-shadow: rgba(0, 0, 0, 0.004) 1px 1px 1px;
  font-family: Montserrat;
  font-weight: 700;
  text-align: center;
  flex: 1;
`;

const InfoWrapper = styled.div`
  -webkit-font-smoothing: antialiased;
  text-align: left;
  text-shadow: rgba(0, 0, 0, 0.004) 1px 1px 1px;
  display: flex;
  justify-content: center;
`;

const ClusterKey: React.FunctionComponent<ClusterKeyProps> = ({
  mobile,
  style,
}): JSX.Element => {
  return mobile ? (
    <Box style={style}>
      <InfoWrapper style={{ alignItems: 'center', flexDirection: 'column' }}>
        <Box flexBox>
          <InfoBox
            style={{
              margin: 0,
              marginBottom: 5,
              width: '15ch',
              minWidth: 0,
              paddingRight: 10,
            }}
          >
            <InfoBoxButton colour={theme.colors.anxiety} />
            <InfoBoxText>Anxiety</InfoBoxText>
          </InfoBox>
          <InfoBox
            style={{
              margin: 0,
              marginBottom: 5,
              width: '15ch',
              minWidth: 0,
              paddingRight: 10,
            }}
          >
            <InfoBoxButton colour={theme.colors.depression} />
            <InfoBoxText>Depression</InfoBoxText>
          </InfoBox>
        </Box>
        <Box flexBox>
          <InfoBox
            style={{
              margin: 0,
              marginBottom: 5,
              width: '15ch',
              minWidth: 0,
              paddingRight: 10,
            }}
          >
            <InfoBoxButton colour={theme.colors.stress} />
            <InfoBoxText>Stress</InfoBoxText>
          </InfoBox>
          <InfoBox
            style={{
              margin: 0,
              marginBottom: 5,
              width: '15ch',
              minWidth: 0,
              paddingRight: 10,
            }}
          >
            <InfoBoxButton colour={theme.colors.minorityStress} />
            <InfoBoxText>Minority Stress</InfoBoxText>
          </InfoBox>
        </Box>
      </InfoWrapper>
    </Box>
  ) : (
    <Box style={style}>
      <InfoWrapper>
        <InfoBox>
          <InfoBoxButton colour={theme.colors.anxiety} />
          <InfoBoxText>Anxiety</InfoBoxText>
        </InfoBox>
        <InfoBox>
          <InfoBoxButton colour={theme.colors.depression} />
          <InfoBoxText>Depression</InfoBoxText>
        </InfoBox>
      </InfoWrapper>
      <InfoWrapper>
        <InfoBox>
          <InfoBoxButton colour={theme.colors.stress} />
          <InfoBoxText>Stress</InfoBoxText>
        </InfoBox>
        <InfoBox>
          <InfoBoxButton colour={theme.colors.minorityStress} />
          <InfoBoxText>Minority Stress</InfoBoxText>
        </InfoBox>
      </InfoWrapper>
    </Box>
  );
};

export default ClusterKey;
