export type ValuesOf<T extends any[]> = T[number];

export interface CategoryWeights {
  Therapy: number;
  Specialism: number;
  Demographic: number;
  Pricing: number;
  Location: number;
}

// @TODO: Contentful integration tests for these types.
export enum TherapyTypes {
  CBT = 'CBT',
  PSYCHODYNAMIC = 'Psychodynamic',
  HUMANISTIC = 'Humanistic',
  RELATIONAL = 'Relational',
  EXISTENTIAL = 'Existential',
  EMDR = 'EMDR',
}

export enum Specialisms {
  ALCOHOL_AND_DRUGS = 'Alcohol & Drugs',
  SEX_AND_SEXUALITY = 'Sex & Sexuality',
  OCD = 'OCD',
  BEREAVEMENT = 'Bereavement',
  TRAUMA = 'Trauma',
  SELF_ESTEEM = 'Self-esteem',
  RELATIONSHIPS = 'Relationships',
  FAMILY = 'Family',
}

export enum Genders {
  MALE = 'Male',
  FEMALE = 'Female',
  TRANSGENDER_MALE = 'Transgender Male',
  TRANSGENDER_FEMALE = 'Transgender Female',
  NON_BINARY = 'Non-binary',
}

export enum Sexualities {
  GAY = 'Gay',
  LESBIAN = 'Lesbian',
  BISEXUAL = 'Bisexual',
  HETEROSEXUAL = 'Heterosexual',
  QUEER = 'Queer',
  PANSEXUAL = 'Pansexual',
}

export enum Ethnicities {
  WHITE = 'White',
  BLACK = 'Black',
  ASIAN = 'Asian',
  LATIN = 'Latin',
  MIDDLE_EASTERN = 'Middle Eastern',
  MIXED = 'Mixed',
}

export enum AgeBands {
  TWENTY_TO_THIRTY = '20-30',
  THIRTY_TO_FORTY = '30-40',
  FORTY_TO_FIFTY = '40-50',
  FIFTY_PLUS = '50',
}

export const distanceBands: number[] = [2.5, 5, 10, 20];

export const distanceDeltaBands: number[] = [2.5, 5, 10];

export const pricingBands: number[] = [25, 50, 75, 100];

export const pricingDelta: number = 25;

export interface Demographics {
  gender: { value: Genders[]; dealbreaker: boolean };
  sexuality: { value: Sexualities[]; dealbreaker: boolean };
  ethnicity: { value: Ethnicities[]; dealbreaker: boolean };
  age: { value: AgeBands[]; dealbreaker: boolean };
}

export interface UserMatchingRecommendation {
  therapies: TherapyTypes[];
  specialisms: Specialisms[];
  demographic: Demographics;
  pricing: { value: ValuesOf<typeof pricingBands>; dealbreaker: boolean };
  distance: { value: ValuesOf<typeof distanceBands>; dealbreaker: boolean };
}

export interface UserMatchingCriteria extends UserMatchingRecommendation {
  location: [number, number];
}

export interface TherapistInput {
  therapistId: string;
  therapies: TherapyTypes[];
  specialisms: Specialisms[];
  demographic: {
    gender: Genders;
    sexuality: Sexualities;
    ethnicity: Ethnicities;
    age: number;
  };
  pricing: number;
  location: [number, number];
}

export interface MatchOutput {
  therapistId: string;
  score: number;
  distance: number;
  dealBroken: boolean;
}

export const therapyTypeMatch = (
  recommendedTherapies: UserMatchingCriteria['therapies'],
  therapist: TherapistInput
): number => {
  const nRecommendations = recommendedTherapies.length;
  const nMatched = recommendedTherapies
    .map<boolean>(x =>
      !!therapist.therapies ? therapist.therapies.includes(x) : false
    )
    .filter(x => x).length;

  // EDGE CASES
  if (!nMatched || !nRecommendations) {
    return 0;
  }

  return nMatched / Math.min(3, nRecommendations);
};

export const specialismsMatch = (
  recommendedSpecialisms: UserMatchingCriteria['specialisms'],
  therapist: TherapistInput
): number => {
  const nRecommendations = recommendedSpecialisms.length;
  const nMatched = recommendedSpecialisms
    .map<boolean>(x =>
      !!therapist.specialisms ? therapist.specialisms.includes(x) : false
    )
    .filter(x => x).length;

  // EDGE CASES
  if (!nRecommendations) {
    return 1;
  }
  if (!nMatched) {
    return 0;
  }

  return 1 - (Math.min(5, nRecommendations) - nMatched) * 0.1;
};

export const demographicMatch = (
  recommendedDemographic: UserMatchingCriteria['demographic'],
  therapist: TherapistInput
): { score: number; dealBroken: boolean } => {
  let score = 0;
  let dealBroken = false;
  if (
    recommendedDemographic.gender.value.includes(
      therapist.demographic.gender
    ) ||
    !recommendedDemographic.gender.value.length
  ) {
    score += 0.25;
  } else if (recommendedDemographic.gender.dealbreaker) {
    dealBroken = true;
  }
  const withinAgeBound = recommendedDemographic.age.value.filter(
    x =>
      therapist.demographic.age >= parseInt(x.split('-')[0]) &&
      (x.split('-').length < 2 ||
        therapist.demographic.age <= parseInt(x.split('-')[1]))
  );
  if (withinAgeBound.length || !recommendedDemographic.age.value.length) {
    score += 0.25;
  } else if (recommendedDemographic.age.dealbreaker) {
    dealBroken = true;
  }
  if (
    recommendedDemographic.ethnicity.value.includes(
      therapist.demographic.ethnicity
    ) ||
    !recommendedDemographic.ethnicity.value.length
  ) {
    score += 0.25;
  } else if (recommendedDemographic.ethnicity.dealbreaker) {
    dealBroken = true;
  }
  if (
    recommendedDemographic.sexuality.value.includes(
      therapist.demographic.sexuality
    ) ||
    !recommendedDemographic.sexuality.value.length
  ) {
    score += 0.25;
  } else if (recommendedDemographic.sexuality.dealbreaker) {
    dealBroken = true;
  }

  return { score, dealBroken };
};

export const pricingMatch = (
  recommendedPricing: UserMatchingCriteria['pricing'],
  therapist: TherapistInput
): { score: number; dealBroken: boolean } => {
  let score: number;
  let dealBroken: boolean = false;
  if (
    therapist.pricing <= recommendedPricing.value ||
    !recommendedPricing.value
  ) {
    score = 1;
  } else {
    score = Math.max(
      1 -
        Math.ceil(
          (therapist.pricing - recommendedPricing.value) / pricingDelta
        ) *
          0.2,
      0
    );
    if (recommendedPricing.dealbreaker) {
      dealBroken = true;
    }
  }
  return { score, dealBroken };
};

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

export const getDistanceFromLatLonInKm = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return Math.round(R * c); // Distance in km
};

export const locationMatch = (
  recommendedLocation: UserMatchingCriteria['distance'],
  userLocation: UserMatchingCriteria['location'],
  therapist: TherapistInput
): { score: number; dealBroken: boolean; calculatedDistance: number } => {
  const distance = getDistanceFromLatLonInKm(
    therapist.location[0],
    therapist.location[1],
    userLocation[0],
    userLocation[1]
  );

  if (distance <= recommendedLocation.value) {
    return { score: 1, dealBroken: false, calculatedDistance: distance };
  }

  const dealBroken = recommendedLocation.dealbreaker;
  let deduction = 0.2;
  let withinBands = false;
  for (let deltaBand of distanceDeltaBands) {
    if (distance <= recommendedLocation.value + deltaBand) {
      withinBands = true;
      break;
    }
    deduction += 0.2;
  }

  if (!withinBands) {
    return { score: 0, dealBroken, calculatedDistance: distance };
  }

  return { score: 1 - deduction, dealBroken, calculatedDistance: distance };
};

export const userTherapistMatch = (
  recommendation: UserMatchingCriteria,
  therapist: TherapistInput,
  weights: CategoryWeights
): MatchOutput => {
  const therapyScore: number =
    therapyTypeMatch(recommendation.therapies, therapist) * weights.Therapy;
  const specialismsScore =
    specialismsMatch(recommendation.specialisms, therapist) *
    weights.Specialism;

  const demographicResults = demographicMatch(
    recommendation.demographic,
    therapist
  );
  const demographicScore = demographicResults.score * weights.Demographic;

  const pricingResults = pricingMatch(recommendation.pricing, therapist);
  const pricingScore = pricingResults.score * weights.Pricing;

  const locationResults = locationMatch(
    recommendation.distance,
    recommendation.location,
    therapist
  );

  const locationScore = locationResults.score * weights.Location;

  const nonScaledScore =
    therapyScore +
    specialismsScore +
    demographicScore +
    pricingScore +
    locationScore;

  const score = (nonScaledScore * 100) / 2 + 50;

  return {
    therapistId: therapist.therapistId,
    score,
    distance: locationResults.calculatedDistance,
    dealBroken:
      locationResults.dealBroken ||
      demographicResults.dealBroken ||
      pricingResults.dealBroken,
  };
};

export type PossibleDemographicResponseValues = string[];

export type PossibleResponseValues = PossibleDemographicResponseValues | number;

export interface MatchResponse {
  questionId: number;
  category: string;
  specificArea: string;
  value: PossibleResponseValues;
  dealbreaker?: boolean;
}

export enum MatchQuestionCategories {
  THERAPY = 'Therapy',
  SPECIALISM = 'Specialism',
  DEMOGRAPHIC = 'Demographic',
  LOCATION = 'Location',
  PRICING = 'Pricing',
}
