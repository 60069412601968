import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from '../../../reusecore/src/elements/Box';
import Text from '../../../reusecore/src/elements/Text';
import Heading from '../../../reusecore/src/elements/Heading';
import Button from '../../../reusecore/src/elements/Button';
import Card from '../../../reusecore/src/elements/Card';
import Image from '../../../reusecore/src/elements/Image';
import FeatureBlock from '../../../superprops/src/components/FeatureBlock';
import Container from '../../../superprops/src/components/UI/Container';
import ImageOne from '../../../superprops/src/assets/image/app/info1.png';
import ImageTwo from '../../../superprops/src/assets/image/app/info2.png';
import { theme } from '../../../theme';
import colors from '../../../theme/colors';
import WellbeingCluster from '../../../components/WellbeingSpheres/WellbeingCluster';
import ClusterKey from '../../../components/WellbeingSpheres/ClusterKey';
import { navigate } from 'gatsby';
import { handleLoginModal } from '../Navbar';

const ControllSection = ({
  sectionWrapper,
  row,
  col,
  title,
  description,
  button,
  textArea,
  imageArea,
  textAreaRow,
  imageAreaRow,
  imageWrapper,
  imageWrapperOne,
  imageWrapperTwo,
  sectionSubTitle,
  //btnStyle,
  subheading,
  heading,
  body,
  buttonText,
  blob,
  flip,
  auth,
}) => {
  return (
    <Box {...sectionWrapper} bg={colors.white} id="learn">
      <Container fullWidth noGutter className="control-sec-container">
        <Box {...row} {...imageAreaRow} mr={flip ? 8 : 6}>
          <Box
            flexBox
            {...col}
            {...imageArea}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <WellbeingCluster />
            <ClusterKey
              style={{ position: 'relative', bottom: '-30%', right: '10%' }}
            />
          </Box>
        </Box>
      </Container>
      <Container>
        <Box {...row} {...textAreaRow}>
          <Box {...col} {...textArea}>
            <div style={{ float: flip ? 'right' : 'left' }}>
              <Text content={subheading} {...sectionSubTitle} />
              <FeatureBlock
                title={<Heading content={heading} {...title} />}
                description={
                  Array.isArray(body) ? (
                    <>
                      {body.map((x, i) => (
                        <Text key={i} index={i} content={x} {...description} />
                      ))}
                    </>
                  ) : (
                    <Text content={body} {...description} />
                  )
                }
                button={
                  Array.isArray(buttonText) ? (
                    <>
                      {buttonText.map((x, i) => (
                        <Button
                          key={i}
                          onClick={() => {
                            auth ? navigate('/user') : handleLoginModal();
                          }}
                          style={{ marginRight: theme.space[3] }}
                          index={i}
                          title={x}
                          {...button}
                        />
                      ))}
                    </>
                  ) : (
                    <Button title={buttonText} {...button} />
                  )
                }
              />
              <Box
                flexBox
                width="100%"
                justifyContent="center"
                mt={7}
                mb={4}
                className="d-flex d-sm-none flex-column"
              >
                <WellbeingCluster
                  style={{ position: 'relative', left: '38%' }}
                  scale={0.1}
                />
                <ClusterKey mobile style={{ marginTop: 70 }} />
              </Box>
            </div>
          </Box>
        </Box>
      </Container>
      {blob && (
        <img
          src="https://res.cloudinary.com/pala-cloudinary/image/upload/v1587116719/oldblob.svg"
          style={{
            position: 'absolute',
            top: flip ? '-10%' : '0',
            right: flip ? undefined : '50%',
            left: flip ? '45%' : undefined,
            zIndex: 1,
            transform: flip ? 'scale(-1,1)' : undefined,
            fill: '#76a99d',
          }}
          className="d-none d-sm-block"
        />
      )}
    </Box>
  );
};

ControllSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  buttonText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  blob: PropTypes.bool,
  flip: PropTypes.bool,
  auth: PropTypes.bool,
};

ControllSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['40px', '220px'],
    pb: ['40px', '220px'],
    position: 'relative',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    flexDirection: 'row-reverse',
    ml: '-15px',
    mr: '-15px',
    position: 'relative',
    zIndex: 8,
  },
  textAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    width: ['100%', '100%', '50%', '50%', '50%'],
  },
  imageArea: {
    width: ['0px', '0px', '45%', '45%', '40%'],
    flexBox: true,
    marginBottom: 60,
  },
  imageWrapper: {
    boxShadow: 'none',
  },
  imageWrapperOne: {
    pointerEvents: 'none',
  },
  imageWrapperTwo: {
    alignSelf: 'flex-end',
    mb: '-60px',
    ml: ['0px', '0px', '-200px', '-250px', '-400px'],
    pointerEvents: 'none',
  },
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    fontSize: '14px',
    letterSpacing: '0.11em',
    fontWeight: '700',
    color: '#3c8271',
    textTransform: 'uppercase',
    mb: '10px',
    textAlign: ['center', 'left'],
  },
  title: {
    fontSize: ['24px', '36px'],
    fontWeight: '600',
    color: '#0f2137',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '420px', '420px'],
    textAlign: ['center', 'left'],
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
    textAlign: ['center', 'left'],
  },
  button: {
    type: 'button',
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
  },
  buttonText: 'CLICK',
};

export default ControllSection;
