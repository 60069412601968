import React, { HTMLAttributes, useState } from 'react';
import styled from 'styled-components';
import WellbeingSphere from './SphereAbsolute';
import Popover from 'react-popover';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e7f8f4;
  font-family: Montserrat;
  flex-direction: column;
`;

const CenterBox = styled.div<{ scale: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.scale * 20 * 4}rem;
  height: ${props => props.scale * 20 * 4}rem;
  position: relative;
`;

const Label = styled.p`
  font-size: 2rem;
  text-align: center;
  font-family: Montserrat;
  font-size: 2.666rem;
  color: grey;
  font-family: 'Montserrat', sans-serif;
  position: relative;
  top: -16rem;
`;

const InfoBox = styled.div`
  -webkit-font-smoothing: antialiased;
  background-color: #fff;
  border-radius: 4em;
  box-shadow: rgba(22, 53, 76, 0.05) 0 7px 25px 0;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  height: 45px;
  margin: 1rem 1rem 2rem 1rem;
  padding: 0 24px 0 6px;
  text-shadow: rgba(0, 0, 0, 0.004) 1px 1px 1px;
  display: flex;
  align-items: center;
  min-width: 17ch;
`;

const InfoBoxButton = styled.div<{ colour: string }>`
  -webkit-font-smoothing: antialiased;
  background-color: ${props => props.colour};
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  box-sizing: border-box;
  color: #fff;
  font-size: 14px;
  margin-bottom: 0;
  margin-right: 0.2rem;
  margin-top: 0;
  text-shadow: rgba(0, 0, 0, 0.004) 1px 1px 1px;
  text-transform: uppercase;
`;

const InfoBoxText = styled.div`
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  color: #0f2137;
  font-size: 1rem;
  margin-bottom: 0;
  margin-top: 0;
  text-shadow: rgba(0, 0, 0, 0.004) 1px 1px 1px;
  font-family: Montserrat;
  font-weight: 700;
  text-align: center;
  flex: 1;
`;

const InfoWrapper = styled.div`
  -webkit-font-smoothing: antialiased;
  text-align: left;
  text-shadow: rgba(0, 0, 0, 0.004) 1px 1px 1px;
  display: flex;
`;

const Heading = styled.h1`
  font-family: Montserrat;
  font-weight: 700;
  font-size: 4rem;
`;

export interface WellbeingClusterProps extends HTMLAttributes<HTMLDivElement> {
  scale?: number;
}

const WellbeingCluster: React.FunctionComponent<WellbeingClusterProps> = ({
  scale,
  style,
  className,
}) => {
  const ORIGINAL_SCALE = scale || 0.2;

  const severity1 = 1.6;
  const severity2 = 2.2;
  const severity3 = 2.6;
  const severity4 = 3;

  return (
    <CenterBox className={className} style={style} scale={ORIGINAL_SCALE}>
      <WellbeingSphere
        severity={(severity1 % 4) + 1}
        category="depression"
        originalScale={ORIGINAL_SCALE}
        style={{
          zIndex: 9,
          left: '15%',
          top: '-45%',
        }}
        number={1}
        secondDelay={0}
      />

      <WellbeingSphere
        severity={(severity2 % 4) + 1}
        category="minorityStress"
        originalScale={ORIGINAL_SCALE}
        style={{
          zIndex: 8,
          left: '35%',
          top: '0%',
        }}
        number={2}
        secondDelay={0.2}
      />

      <WellbeingSphere
        severity={(severity3 % 4) + 1}
        category="stress"
        originalScale={ORIGINAL_SCALE}
        style={{
          zIndex: 7,
          left: '-30%',
          top: '45%',
        }}
        number={3}
        secondDelay={0.4}
      />

      <WellbeingSphere
        severity={(severity4 % 4) + 1}
        category="anxiety"
        originalScale={ORIGINAL_SCALE}
        style={{
          zIndex: 6,
          position: 'absolute',
          left: '-60%',
          top: '-25%',
        }}
        number={4}
        secondDelay={0.6}
      />
    </CenterBox>
  );
};

export default WellbeingCluster;
