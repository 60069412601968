import React, { HTMLAttributes, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Popover from 'react-popover';
import { theme } from '../../theme';
import { darken } from 'polished';

const floatAnimation = keyframes`
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 1.75rem); }
  100%   { transform: translate(0, -0px); }    
`;

interface CommonSphereElementProps {
  scale?: number;
}

interface BallProps extends CommonSphereElementProps {
  colourGradient: [string, string];
}
const Ball = styled.figure<BallProps>`
  display: block;
  background: pink;
  margin: 0;
  border-radius: 50%;
  height: ${props => props.scale * 20}rem;
  width: ${props => props.scale * 20}rem;
  background: radial-gradient(
    circle at ${props => props.scale * 6.666}rem
      ${props => props.scale * 4.666}rem,
    #ffffff,
    ${props => props.colourGradient[0]},
    ${props => props.colourGradient[1]}
  );
`;

interface StageProps extends CommonSphereElementProps {
  secondDelay: number;
}

const Stage = styled.section<StageProps>`
  width: ${props => props.scale * 20}rem;
  height: ${props => props.scale * 20}rem;
  display: inline-block;
  margin: ${props => props.scale * 1.333}rem;
  perspective: ${props => props.scale * 26.666}rem;
  perspective-origin: 90% 5%;
  animation: ${floatAnimation} 3s ease-in-out
    ${props => props.secondDelay || 0}s infinite;
`;

interface ShadowProps extends CommonSphereElementProps {
  secondDelay: number;
}

const Shadow = styled.span<ShadowProps>`
  position: absolute;
  width: 150%;
  height: 110%;
  background: radial-gradient(
    circle at 50% 50%,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.1) 40%,
    rgba(0, 0, 0, 0) 50%
  );
  transform: rotateX(20deg) translateZ(${props => props.scale * -7}rem);
  z-index: -1;
  animation: ${floatAnimation} 3s ease-in-out
    ${props => props.secondDelay || 0}s infinite;
`;

const Number = styled.div<CommonSphereElementProps>`
  display: block;
  position: absolute;
  text-align: center;
  height: ${props => props.scale * 5.333}rem;
  width: ${props => props.scale * 6.666}rem;
  left: ${props => props.scale * 3.333}rem;
  margin-left: ${props => props.scale * 3.333}rem;
  top: ${props => props.scale * 2.933}rem;
  margin-top: ${props => props.scale * 3.333}rem;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: ${props => props.scale * 10}rem;
  line-height: ${props => props.scale * 6.933}rem;
  text-shadow: ${props => props.scale * -0.066}rem
      ${props => props.scale * -0.066}rem ${props => props.scale * 0.066}rem
      #fff,
    ${props => props.scale * 0.2}rem ${props => props.scale * 0.2}rem
      ${props => props.scale * 0.4}rem #1c313e;
  opacity: 0.3;
  font-weight: 500;
`;

interface SphereWrapperProps {
  originalScale: number;
  severity: number;
}

export const SphereWrapper = styled.div<SphereWrapperProps>`
  width: ${props => props.originalScale * (props.severity || 3) * 20}rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
`;

const PopoverBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  background: hsl(0, 0%, 27%);
  color: white;
  border-radius: 0.3rem;
  font-family: Montserrat;
  max-width: 20ch;
  text-align: center;
`;

export interface SphereAbsoluteProps
  extends StageProps,
    SphereWrapperProps,
    CommonSphereElementProps,
    ShadowProps,
    HTMLAttributes<HTMLDivElement> {
  category: 'anxiety' | 'depression' | 'stress' | 'minorityStress';
  number: number;
}

const SphereAbsolute: React.FunctionComponent<SphereAbsoluteProps> = ({
  severity,
  category,
  originalScale,
  style,
  number,
  secondDelay,
}) => {
  const CATEGORY_COLOUR_MAP: { [index: string]: [string, string] } = {
    anxiety: [theme.colors.anxiety, darken(0.25, theme.colors.anxiety)],
    depression: [
      theme.colors.depression,
      darken(0.25, theme.colors.depression),
    ],
    stress: [theme.colors.stress, darken(0.25, theme.colors.stress)],
    minorityStress: [
      theme.colors.minorityStress,
      darken(0.25, theme.colors.minorityStress),
    ],
  };
  // scale is 1, 2, 3 or 4.
  const scale = severity * originalScale;

  return (
    <SphereWrapper
      originalScale={originalScale}
      severity={severity}
      style={style}
    >
      <Stage scale={scale} secondDelay={secondDelay}>
        <Ball scale={scale} colourGradient={CATEGORY_COLOUR_MAP[category]}>
          <Shadow scale={scale} secondDelay={secondDelay} />
          <Number scale={scale}>{number}</Number>
        </Ball>
      </Stage>
    </SphereWrapper>
  );
};

export default SphereAbsolute;
